import { Analytics } from "cf-constants";
import { gtmDataLayerPush } from "cf-utils";
import Link from "next/link";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux/types";
import { publicAssets } from "static-data";
import { ImageComponent } from "../src/components/to-components";

import styles from "styles/error-404.module.scss";

const Custom404: React.FC = () => {
  const selectedMarket = useSelector(({ Market }: Store) => {
    return Market?.selectedMarket[0];
  });

  useEffect(() => {
    gtmDataLayerPush(
      {
        event: Analytics.CustomGTMEvent.TO_PAGE_VIEW,
        payload: { pageTitle: "" },
      },
      selectedMarket.categories,
      {
        url: "/404",
        query: { market: selectedMarket.slug },
      }
    );
  }, []);

  return (
    <div className={styles.root}>
      <ImageComponent
        className={styles.image}
        href={publicAssets.images.rocket}
      />
      <div className={styles.text}>
        Oooooooops! Page not found.
        <p>
          Go to the{" "}
          <Link href={"/"} passHref>
            <a className={styles.link}>shop homepage</a>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Custom404;
