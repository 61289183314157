import { useQuery } from "@apollo/client";
import { Analytics } from "cf-constants";
import { Collection, CollectionEvents, Market } from "cf-types";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMarket } from "redux/actions";
import { Store, UPDATE_PAGE_DATA } from "redux/types";
import { CategoryPageTemplate } from "src/components";
import { useDeviceId } from "src/hooks";
import { CollectionList } from "../src/components/to-components";
import {
  GetCategoryEventGroupsQuery,
  GetCollectionsQuery,
  GetMarketsQuery,
} from "./api/queries";
import { normaliseQueryParam } from "src/utils";
import Error404 from "./404";

import styles from "styles/home.module.scss";
import { CollectionWidgetProps } from "src/components/to-components/checkout/web/collections/collection-widget";

const pev2 = "Offer selection";

const Home: React.FC = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [deviceId] = useDeviceId();
  const selectedMarket = useSelector(({ Market }: Store) => {
    return Market?.selectedMarket[0];
  });

  useEffect(() => {
    dispatch({ type: UPDATE_PAGE_DATA, payload: { pageName: "home" } });
  }, []);

  /**
   * 
   * TODO: Disable until geo location logic is fleshed out
   * 
   const location = normaliseQueryParam(router.query.location);
   const getApiKey = () => {
     const { api_key } = JSON.parse(localStorage.getItem("login") || "{}");
     return api_key;
   };
   * 
   const { loading: currentMarketLoading } = useQuery<{
     getDeviceData: Device;
   }>(GetDeviceDataQuery, {
     fetchPolicy: "no-cache",
     onCompleted: ({ getDeviceData }) => {
       dispatch(setSelectedMarket([getDeviceData.currentMarket]));
     },
     skip: !!location || !deviceId,
     variables: {
       deviceData: JSON.stringify({
         authorization: loggedIn ? getApiKey() : "",
         deviceId,
       }),
     },
   });
   */

  const location = normaliseQueryParam(router.query.location);

  const { loading: currentMarketLoading } = useQuery<{
    getMarkets: { objects: Market[] };
  }>(GetMarketsQuery, {
    fetchPolicy: "cache-first",
    onCompleted: ({ getMarkets }) => {
      dispatch(setSelectedMarket(getMarkets.objects));
    },
    skip: !!location || !deviceId,
    variables: {
      marketData: JSON.stringify({
        market: "london",
        deviceId,
      }),
    },
  });

  const feautured = selectedMarket.categories[0] ?? {};
  const { data: productListData, loading } = useQuery<CollectionEvents>(
    GetCategoryEventGroupsQuery,
    {
      fetchPolicy: "no-cache",
      skip: !selectedMarket.slug || !deviceId || !feautured.slug,
      variables: {
        categoryData: JSON.stringify({
          category: feautured.slug,
          deviceId,
          market: selectedMarket.slug,
        }),
      },
    }
  );

  const { data: collectionListData } = useQuery<{
    getCollections: CollectionWidgetProps[];
  }>(GetCollectionsQuery, {
    fetchPolicy: "no-cache",
    query: GetCollectionsQuery,
    skip: !selectedMarket.slug || !deviceId,
    variables: {
      collectionData: JSON.stringify({
        deviceId,
        market: selectedMarket.slug,
      }),
    },
  });

  if (productListData?.getCategoryEvents.error) {
    return <Error404 />;
  }

  const category = Object.assign({}, productListData?.getCategoryEvents);
  const isLoading = loading || currentMarketLoading || !selectedMarket.slug;

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <CategoryPageTemplate
          adobePev2={pev2}
          category={category}
          loading={isLoading}
          pageName={Analytics.PageName.HOME}
        />
        {selectedMarket.slug === "london" && collectionListData && (
          <CollectionList items={collectionListData.getCollections} />
        )}
      </main>
    </div>
  );
};

export default Home;
